@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import '../assets/styles/variables';
@import '~compass-mixins/lib/compass';
@import '~compass-mixins/lib/animate';
@import '~bootstrap/scss/bootstrap';
@import '../assets/styles/fonts';
@import '../assets/styles/functions';

/* === Icon fonts === */
@import '~@mdi/font/scss/materialdesignicons';
@import '~flag-icon-css/sass/flag-icon';

/* === Template mixins === */
@import '../assets/styles/mixins/animation';
@import '../assets/styles/mixins/buttons';
@import '../assets/styles/mixins/misc';
@import '../assets/styles/mixins/color-functions';
@import '../assets/styles/mixins/cards';
@import '../assets/styles/mixins/blockqoute';

/* === Core Styles === */
@import '../assets/styles/background';
@import '../assets/styles/typography';
@import '../assets/styles/reset';
@import '../assets/styles/responsive';
@import '../assets/styles/misc';
@import '../assets/styles/utilities';
@import '../assets/styles/spinner';
@import '../assets/styles/dashboard';

/* === Components === */
@import '../assets/styles/components/forms';
@import '../assets/styles/components/checkbox-radio';
@import '../assets/styles/components/icons';
@import '../assets/styles/components/tables';
@import '../assets/styles/components/buttons';
@import '../assets/styles/components/breadcrumbs';
@import '../assets/styles/components/cards';
@import '../assets/styles/components/preview';
@import '../assets/styles/components/bootstrap-progress';
@import '../assets/styles/components/lists';
@import '../assets/styles/components/tabs';
@import '../assets/styles/components/dropdowns';
@import '../assets/styles/components/loaders/loaders';
@import '../assets/styles/components/spinner';

@import '../assets/styles/components/landing-screens/auth';

/* === Layout === */
@import '../assets/styles/navbar';
@import '../assets/styles/sidebar';
@import '../assets/styles/footer';
@import '../assets/styles/layout';
@import '../assets/styles/palette';
@import '../assets/styles/settings-panel';
